import React from "react"
import { Link } from "gatsby"
import { FooterContainer } from "./style"
import Logo from "../../../static/img/TanghoLogo.svg"

const links = [
  {
    path: "/solutions",
    name: "What We Do",
  },
  {
    path: "/research",
    name: "How We Do It",
  },
  {
    path: "/hemp",
    name: "Why Hemp?",
  },
  {
    path: "/about",
    name: "About",
  },
  {
    path: "/contact",
    name: "Contact Us",
  },
]

const index = () => {
  return (
    <FooterContainer>
      <div>
        <Link to="/">
          <img width="132px" src={Logo} />
        </Link>
        {links &&
          links.map((link, i) => {
            return (
              <Link
                key={i}
                activeClassName="active"
                className="nav-links"
                to={link.path}
              >
                {link.name}
              </Link>
            )
          })}
        <footer>
          Copyright &copy; {new Date().getFullYear()}. Tangho. All rights
          reserved.
        </footer>
      </div>
    </FooterContainer>
  )
}

export default index
