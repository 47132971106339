import styled from "styled-components"

export const NavbarWrapper = styled.div`
  .navbar {
    background-color: transparent;
    height: 80px;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    font-size: 1.2rem;
    position: absolute;
    top: 0;
    z-index: 20;
  }

  .navbar.clicked {
    background: ${({
      theme: {
        main: { offBlack },
      },
    }) => offBlack};
    border: none;
  }

  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    max-width: 1500px;

    @media screen and (min-width: 960px) {
      margin-top: 50px;
    }
  }

  .main-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: rgba(0, 0, 0, 0.3); */
  }

  .nav-logo {
    color: ${({
      theme: {
        main: { offWhite },
      },
    }) => offWhite};
    width: 132px;
    max-width: 132px;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    flex-grow: 1;

    @media screen and (min-width: 960px) {
      width: 170px;
      max-width: 170px;
      margin-left: 20px;
      margin-top: 18px;
    }

    @media screen and (min-width: 1300px) {
      margin-left: 80px;
    }

    img {
      @media screen and (min-width: 960px) {
        height: 50px;
      }
    }
  }

  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;

    @media screen and (min-width: 960px) {
      margin-right: 20px;
    }

    @media screen and (min-width: 1300px) {
      margin-right: 100px;
    }
  }

  .nav-links {
    color: ${({
      theme: {
        main: { offWhite },
      },
    }) => offWhite};
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
  }
  .nav-links:hover {
    color: ${({
      theme: {
        main: { bottleGreen },
      },
    }) => bottleGreen};
  }
  .nav-links:focus {
    color: ${({
      theme: {
        main: { offBlack },
      },
    }) => offBlack};
  }
  .fa-code {
    margin-left: 1rem;
  }

  .nav-item {
    line-height: 40px;
    margin-right: 1rem;
  }

  .nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  }

  /* .nav-item:hover:after {
    width: 100%;
    background: ${({
    theme: {
      main: { bottleGreen },
    },
  }) => bottleGreen};
  } */

  .nav-item .active {
    color: ${({
      theme: {
        main: { offWhite },
      },
    }) => offWhite};
    border-bottom: 1px solid
      ${({
        theme: {
          main: { bottleGreen },
        },
      }) => bottleGreen};
  }

  .nav-icon {
    display: none;
  }

  @media only screen and (max-width: 959px) {
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      /* border-top: 1px solid
        ${({
        theme: {
          main: { offWhite },
        },
      }) => offWhite}; */
      position: absolute;
      top: 80px;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
    }

    .nav-menu.active {
      background: ${({
        theme: {
          main: { offBlack },
        },
      }) => offBlack};
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
    .nav-item .active {
      color: ${({
        theme: {
          main: { offWhite },
        },
      }) => offWhite};
      border: none;
    }
    .nav-links {
      padding: 1.5rem;
      width: 100%;
      display: table;
    }

    .nav-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      color: ${({
        theme: {
          main: { offWhite },
        },
      }) => offWhite};
    }
  }
`
