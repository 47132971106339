export const TanghoTheme: ThemeType = {
  main: {
    offWhite: "#FCFBFB",
    offBlack: "#020202",
    tanghoGreen: "#4DFFC1",
    bottleGreen: "#3F8070",
    red: "#EB5757",
    grey: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #EDEDED",
  },
  breakpoints: {
    tablet: 460,
    desktop: 875,
  },
}

export type ThemeType = {
  main: {
    offWhite: string
    offBlack: string
    tanghoGreen: string
    bottleGreen: string
    red: string
    grey: string
  }
  backgroundImage?: string | HTMLImageElement
  breakpoints: {
    tablet: number | string
    desktop: number | string
  }
}
