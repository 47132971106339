import React from "react"
import { TanghoTheme as theme } from "../Themes"
import { ThemeProvider } from "styled-components"
import { GlobalStyles } from "./GlobalStyles"
import Navbar from "../Navbar"
import Footer from "../Footer"
import Logo from "../../static/img/TanghoLogo.svg"

type props = {
  children: JSX.Element | JSX.Element[]
}

const index = ({ children }: props) => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <div>
          <Navbar />
          {children}
        </div>
        <Footer />
      </ThemeProvider>
    </div>
  )
}

export default index
