import { createGlobalStyle } from "styled-components"
import { ThemeType } from "../Themes"
import GrafierBlack from "../../fonts/Grafier-Black.otf"
import GrafierBold from "../../fonts/Grafier-Bold.otf"
import GrafierRegular from "../../fonts/Grafier-Regular.otf"
import Florensans from "../../fonts/Florensans.otf"

export const GlobalStyles = createGlobalStyle<{ theme: ThemeType }>`
    
    @font-face {
        font-family: grafier;
        src: url(${GrafierRegular}) format('opentype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: grafierBold;
        src: url(${GrafierBold}) format('opentype');
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: grafierBlack;
        src: url(${GrafierBlack}) format('opentype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'florensans';
        src: url(${Florensans}) format('opentype');
        font-weight: normal;
        font-style: normal;
    }
    
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        transition: 0.3s all ease;
    }

    a {
      text-decoration: none;
    }

    body {
        background: ${({
          theme: {
            main: { bottleGreen, offWhite },
          },
        }) => offWhite};
    }

    ::selection {
  background: ${({
    theme: {
      main: { bottleGreen },
    },
  }) => bottleGreen}; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: ${({
    theme: {
      main: { bottleGreen },
    },
  }) => bottleGreen}; /* Gecko Browsers */
}
    
    // headings
    h1 {
        font-family: 'Florensans';
        font-weight: 400;
        font-size: 36px;
        line-height: 36px;
        text-align: center;
        color: ${({
          theme: {
            main: { offBlack },
          },
        }) => offBlack};

        @media only screen and (min-width: 960px) {
            font-size: 64px;
            line-height: 64px;
        }
    }
    h2 {
        font-family: 'Grafier';
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: ${({
          theme: {
            main: { offWhite },
          },
        }) => offWhite};

        @media only screen and (min-width: 960px) {
            font-size: 36px;
            line-height: 48px;
        }
    }

    .custom-dots {
      display: flex !important;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      >li>button {
        font-size: 0;
    line-height: 0;
      }

      height: 100px;
      color: ${({
        theme: {
          main: { grey },
        },
      }) => grey};
      background: yellow;

    }

    .slick-active {
      color: ${({
        theme: {
          main: { bottleGreen },
        },
      }) => bottleGreen};
    }

    .slick-dots {
      bottom: 35px;

      li {
        height: 15px;
        width: 15px;
        border-radius: 50%50%;
        vertical-align: middle;
        background-color: ${({
          theme: {
            main: { offWhite },
          },
        }) => offWhite};

        button:before {
          line-height: 15px;
          height: 15px;
          width: 15px;
          color: ${({
            theme: {
              main: { offWhite },
            },
          }) => offWhite};
        }
      }

      li.slick-active {
        background: ${({
          theme: {
            main: { bottleGreen },
          },
        }) => bottleGreen};
      }

      li.slick-active button:before {
        line-height: 15px;
        height: 15px;
        width: 15px;
        color: ${({
          theme: {
            main: { bottleGreen },
          },
        }) => bottleGreen};
      }
    }

    .slick-dots.images {
      bottom: 35px;

      li {
        height: 15px;
        width: 15px;
        border-radius: 50%50%;
        vertical-align: middle;
        background-color: ${({
          theme: {
            main: { bottleGreen },
          },
        }) => bottleGreen}50;

        button:before {
          line-height: 15px;
          height: 15px;
          width: 15px;
          color: ${({
            theme: {
              main: { bottleGreen },
            },
          }) => bottleGreen}50;
        }
      }

      li.slick-active {
        background: ${({
          theme: {
            main: { bottleGreen },
          },
        }) => bottleGreen};
      }

      li.slick-active button:before {
        line-height: 15px;
        height: 15px;
        width: 15px;
        color: ${({
          theme: {
            main: { bottleGreen },
          },
        }) => bottleGreen};
      }
    }
  
`
