import React, { useState } from "react"
import { Link } from "gatsby"
import menuIcon from "../../../static/img/menu-icon.svg"
import closeIcon from "../../../static/img/close.svg"
import Logo from "../../../static/img/tangho_branco.svg"
import { NavbarWrapper } from "./style"

const links = [
  {
    path: "/solutions",
    name: "What We Do",
  },
  {
    path: "/research",
    name: "How We Do It",
  },
  {
    path: "/hemp",
    name: "Why Hemp?",
  },
  {
    path: "/about",
    name: "About",
  },
  {
    path: "/contact",
    name: "Contact Us",
  },
]

const index = () => {
  const [click, setClick] = React.useState(false)

  const handleClick = () => setClick(!click)
  const Close = () => setClick(false)

  return (
    <NavbarWrapper>
      <div className={click ? "main-container" : ""} onClick={() => Close()} />
      <nav
        className={click ? "navbar clicked" : "navbar"}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="nav-container">
          <Link to="/" className="nav-logo">
            <img src={Logo} />
          </Link>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            {links &&
              links.map((link, i) => {
                return (
                  <li className="nav-item" key={i}>
                    <Link
                      activeClassName="active"
                      className="nav-links"
                      onClick={
                        click
                          ? handleClick
                          : () => {
                              return null
                            }
                      }
                      to={link.path}
                    >
                      {link.name}
                    </Link>
                  </li>
                )
              })}
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            {click ? <img src={closeIcon} /> : <img src={menuIcon} />}
          </div>
        </div>
      </nav>
    </NavbarWrapper>
  )
}

export default index
