import styled from "styled-components"

export const FooterContainer = styled.div`
  padding: 20px;
  width: 100%;
  background: ${({
    theme: {
      main: { offBlack },
    },
  }) => offBlack};
  > div {
    display: flex;
    margin: auto;
    max-width: 1100px;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    height: 270px;
    padding: 24px 16px 24px 16px;
    align-items: center;
    align-content: center;
    text-align: center;
    background: ${({
      theme: {
        main: { offBlack },
      },
    }) => offBlack};
    color: ${({
      theme: {
        main: { offWhite },
      },
    }) => offWhite};
    font-size: 14px;
    line-height: 18px;
    font-family: "Grafier";
    > footer {
      opacity: 0.5;
      font-size: 12px;
    }
    > * {
      padding: 8px;
    }
    .active {
      border-bottom: 1px solid
        ${({
          theme: {
            main: { bottleGreen },
          },
        }) => bottleGreen};
    }
    > a {
      color: ${({
        theme: {
          main: { offWhite },
        },
      }) => offWhite};
      text-decoration: none;
    }
    > a:hover {
      color: ${({
        theme: {
          main: { bottleGreen },
        },
      }) => bottleGreen};
    }
    > a:active {
      color: ${({
        theme: {
          main: { bottleGreen },
        },
      }) => bottleGreen};
    }
  }

  @media only screen and (min-width: 875px) {
    > div {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
`
